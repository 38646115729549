import styled from "styled-components"

import { COLORS, SIZES } from "src/layout/constants"

export const Label = styled.div`
  color: ${COLORS.grey};
  text-transform: uppercase;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 3px;
`
export const Top = styled.div`
  text-align: center;
  margin-top: ${SIZES.margin * 2}px;
  h1 {
    font-family: Jost, sans-serif;
    font-weight: 500;
  }
  h2 {
    font-size: 2em;
    margin: 0 0 0.5em;
  }
  @media (max-width: 799px) {
    margin: ${SIZES.mobileMargin}px;
  }
`
export const Top2 = styled.div`
  white-space: pre-line;
  margin: ${SIZES.mobileMargin}px;
  p {
    font-size: 1.2em;
    line-height: 1.5em;
    letter-spacing: 2px;
    max-width: 500px;
  }
  a {
    text-decoration: underline;
  }
  @media (min-width: 800px) {
    max-width: ${SIZES.maxWidth}px;
    margin: ${SIZES.margin * 2}px auto ${SIZES.margin}px;
    padding: 0 ${SIZES.margin * 2}px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > * {
      flex: 1;
    }
    h1 {
      margin-right: ${SIZES.margin}px;
    }
  }
`
export const Section = styled.section`
  @media (min-width: 800px) {
    padding: ${SIZES.margin}px;
    max-width: ${SIZES.maxWidth}px;
    margin: 0 auto;
  }
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Column = styled.div`
  flex: 1;
  margin: ${SIZES.margin}px;
  min-width: 200px;
  p {
    white-space: pre-line;
  }
  @media (max-width: 799px) {
    margin: ${SIZES.mobileMargin}px;
  }
`
export const Sheet = styled.section`
  margin: ${SIZES.margin}px 0;
  @media (min-width: 800px) {
    display: flex;
    background-color: ${COLORS.light};
  }
`
export const ImageColumn = styled.div`
  flex: 2;
  @media (max-width: 799px) {
    margin: ${SIZES.mobileMargin}px;
  }
`
export const Square = styled.div`
  padding-bottom: 100%;
  position: relative;
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
export const Subtitle = styled.h2`
  font-family: Jost, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: ${COLORS.grey};
  font-size: 1.2em;
  letter-spacing: 3px;
`
export const Specs = styled.div`
  flex: 1;
  background-color: ${COLORS.light};
  h3 {
    text-transform: lowercase;
    font-size: 2em;
    margin: 0;
  }
  h4 {
    color: ${COLORS.grey};
    margin: 5px 0 20px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${SIZES.margin}px ${SIZES.margin * 2}px ${SIZES.margin / 2}px;
  @media (max-width: 799px) {
    padding: ${SIZES.mobileMargin}px ${SIZES.mobileMargin}px ${SIZES.mobileMargin / 2}px;
  }
`
export const Bottom = styled.div`
  padding-top: 15px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.7em;
  letter-spacing: 2px;
  line-height: 3em;
  margin-top: 10px;
  @media (min-width: 800px) {
    margin-top: ${SIZES.margin * 2}px;
    border-top: 1px solid ${COLORS.black};
  }
`
export const Text = styled.p`
  font-size: 1.2em;
  letter-spacing: 3px;
  line-height: 1.5em;
  white-space: pre-line;
`
export const Caption = styled.figcaption`
  letter-spacing: 3px;
  line-height: 1.4em;
  margin: 0.5em 0;
`

export const Box = styled.section`
  background-color: ${COLORS.light};
  a {
    text-decoration: underline;
  }
  ${Text} {
    max-width: 600px;
  }

  padding: ${SIZES.mobileMargin}px;

  @media (min-width: 800px) {
    display: flex;
    max-width: ${SIZES.maxWidth - 2 * SIZES.margin}px;
    margin: ${SIZES.margin}px auto;
    padding: 0;
    > * {
      padding: ${SIZES.margin}px;
      flex: 1;
    }
  }
`
