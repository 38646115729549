import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Layout from "src/layout"
import { useLang } from "src/layout/root"
import { COLORS, SIZES } from "src/layout/constants"
import { Top2, Section, Row } from "src/components/styled"
import DownloadIcon from "src/svg/download.svg"
import DocIcon from "src/svg/doc.svg"
import WheelIcon from "src/svg/wheel.svg"
import SprayIcon from "src/svg/spray.svg"

const Box = styled.section`
  background-color: ${COLORS.light};
  padding: ${SIZES.margin}px;
  @media (max-width: 799px) {
    margin: ${SIZES.mobileMargin}px;
    padding: ${SIZES.mobileMargin}px;
  }
`
const Container = styled(Row)`
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: ${SIZES.margin}px;
    @media (min-width: 800px) {
      padding-bottom: 20px;
      border-bottom: 2px solid ${COLORS.black};
    }
  }
`
const Category = styled.div`
  flex: 1;
  min-width: 200px;
  svg {
    margin: -16px;
  }
  h2 {
    font-size: 2em;
  }
  @media (min-width: 800px) {
    padding-right: ${SIZES.margin}px;
  }
`
const List = styled.div`
  flex: 4;
  min-width: 200px;
`
const FileLink = styled.a`
  display: block;
  font-size: 1.2em;
  position: relative;
  svg {
    position: absolute;
    top: 13px;
    right: 0;
  }
  padding: 20px 40px 20px 0;
  border-bottom: 2px solid ${COLORS.black};
`

interface Cat {
  id: Attachment["category"]
  Icon: any
}

const CATEGORIES: Cat[] = [
  { id: "catalogue", Icon: DocIcon },
  { id: "guide", Icon: WheelIcon },
  { id: "entretien", Icon: SprayIcon },
  { id: "conception", Icon: WheelIcon },
]

interface DataType {
  attachments: {
    nodes: Attachment[]
  }
}

const CatalogPage: GatsbyPage<DataType> = ({ data }) => {
  const { getNodes } = useLang()
  const attachments = getNodes<Attachment>(data.attachments)

  return (
    <Layout title="catalog">
      <Top2>
        <FormattedMessage id="catalog.title" tagName="h1" />
        <FormattedMessage id="catalog.text" tagName="p" />
      </Top2>
      <Section>
        <Box>
          {CATEGORIES.map(({ id, Icon }) => {
            const list = attachments.filter(({ category }) => category === id)
            if (!list.length) {
              return null
            }
            return (
              <Container key={id}>
                <Category>
                  <Icon />
                  <FormattedMessage id={`catalog.${id}`} tagName="h2" />
                </Category>
                <List>
                  {list.map(({ contentful_id, title, file }) => (
                    <FileLink key={contentful_id} href={file.file.url}>
                      {title}
                      <DownloadIcon />
                    </FileLink>
                  ))}
                </List>
              </Container>
            )
          })}
        </Box>
      </Section>
    </Layout>
  )
}

export default CatalogPage

export const query = graphql`
  query {
    attachments: allContentfulFichier(sort: { fields: position }) {
      nodes {
        node_locale
        contentful_id
        category
        title
        file {
          file {
            url
          }
        }
      }
    }
  }
`
